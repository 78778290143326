import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import createPersistedState from 'vuex-persistedstate';
import { LOCAL_STORAGE_KEY } from '~/constants';

const storage = {
  getItem: (key: string) => {
    const previousState = localStorage.getItem(key);
    if (previousState === null) return null;
    return JSON.parse(previousState);
  },
  setItem: (key: string, value: any) => localStorage.setItem(key, JSON.stringify(value)),
  removeItem: (key: string) => localStorage.removeItem(key),
};

const SessionStorage = {
  getItem: (key: string) => {
    const previousState = sessionStorage.getItem(key);
    if (previousState === null) return null;
    return JSON.parse(previousState);
  },
  setItem: (key: string, value: any) => sessionStorage.setItem(key, JSON.stringify(value)),
  removeItem: (key: string) => sessionStorage.removeItem(key),
};

export default defineNuxtPlugin(({ store, app }) => {
  createPersistedState({
    key: LOCAL_STORAGE_KEY.GUEST_TOKEN,
    paths: ['modules.cart.guestToken'],
  })(store);

  createPersistedState({
    key: LOCAL_STORAGE_KEY.USER_OPTION,
    paths: ['modules.order.userOption'],
  })(store);

  createPersistedState({
    key: LOCAL_STORAGE_KEY.ORDER_USER_REGISTER,
    paths: ['modules.order.register'],
    storage: window.sessionStorage,
  })(store);

  createPersistedState({
    key: LOCAL_STORAGE_KEY.ORDER_USER_REGISTER_CART_ID,
    paths: ['modules.order.registerCartId'],
    storage: window.sessionStorage,
  })(store);

  createPersistedState({
    key: LOCAL_STORAGE_KEY.ARCHIVES_READ_AT,
    paths: ['internals.header.archivesReadAt'],
  })(store);

  createPersistedState({
    key: LOCAL_STORAGE_KEY.ARTICLES_READ_AT,
    paths: ['internals.header.articlesReadAt'],
  })(store);

  createPersistedState({
    key: LOCAL_STORAGE_KEY.STAFFREVIEWS_READ_AT,
    paths: ['internals.header.staffReviewsReadAt'],
  })(store);

  createPersistedState({
    key: LOCAL_STORAGE_KEY.WINESET_READ_AT,
    paths: ['internals.header.wineSetReadAt'],
  })(store);

  createPersistedState({
    key: LOCAL_STORAGE_KEY.CELLAR_CREDIT,
    paths: ['modules.mypage.cellar.isComeFromCredit'],
  })(store);

  createPersistedState({
    key: LOCAL_STORAGE_KEY.CELLAR_POLICY,
    paths: ['modules.mypage.cellar.isComeFromPolicy'],
  })(store);

  createPersistedState({
    key: LOCAL_STORAGE_KEY.CELLAR_ORDER,
    paths: ['modules.mypage.cellar.toOrderFromCellar'],
  })(store);

  createPersistedState({
    key: LOCAL_STORAGE_KEY.EVENT_RESERVE,
    paths: ['event.reserve'],
    storage,
  })(store);

  createPersistedState({
    key: LOCAL_STORAGE_KEY.EVENT_RESERVATION_UPDATE_BODY,
    paths: ['modules.mypage.event.reservationInfo'],
    storage,
  })(store);

  createPersistedState({
    key: LOCAL_STORAGE_KEY.ORDER_VERITRANS_TEMPORARY,
    paths: ['modules.order.veritransOrderedTemporals'],
    storage: SessionStorage,
  })(store);

  createPersistedState({
    key: LOCAL_STORAGE_KEY.ORDER_CONVERSION_VALUE,
    paths: ['modules.order.orderConversionValue'],
    storage: SessionStorage,
  })(store);

  createPersistedState({
    key: LOCAL_STORAGE_KEY.KEEP_SCROLL,
    paths: ['internals.keepScroll'],
    storage: SessionStorage,
  })(store);

  createPersistedState({
    key: LOCAL_STORAGE_KEY.IS_USED_COUPON,
    paths: ['modules.order.isUsedCoupon'],
  })(store);

  createPersistedState({
    key: LOCAL_STORAGE_KEY.AMAZON_PAY_CHECKOUT_SESSION_ID,
    paths: ['modules.amazonPay.checkoutSessionId'],
    storage: {
      getItem: (key: string) => app.$cookies.get(key),
      setItem: (key: string, value: any) => {
        // 1日で無効になるクッキーをセットする(AmazonCheckoutSessionIdは1日で無効になるため)
        app.$cookies.set(key, value, { path: '/', expires: new Date(Date.now() + 1000 * 60 * 60 * 24) });
      },
      removeItem: (key: string) => app.$cookies.remove(key),
    },
  })(store);
});
